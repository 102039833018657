export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "releaseNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Release Note"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource Package Content"])},
        "compare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compare installed version with target"])},
        "compareTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compare Resource Packages"])}
      },
      "zh": {
        "releaseNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["版本说明"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资源包内容"])},
        "compare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对比已安装版本与目标版本"])},
        "compareTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对比资源包："])}
      }
    }
  })
}
