export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "addSshKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Private Key"])},
        "ansible_host_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Should provide at node level."])},
        "password_and_bastion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are using bastion/jumpserver to access the node, if you use password here, it takes longer time to create ssh connection, it would be better to clear the password and provide ssh_private_key."])},
        "speedup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSH Multiplexing"])},
        "rootuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must use root user"])}
      },
      "zh": {
        "addSshKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加私钥"])},
        "ansible_host_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必须在节点级别设置"])},
        "password_and_bastion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您将使用跳板机访问节点，如果在此处使用密码访问，创建 ssh 连接的时间较长，建议清除密码并提供 “私钥文件”。"])},
        "speedup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加速执行"])},
        "rootuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必须使用 root 用户"])}
      }
    }
  })
}
