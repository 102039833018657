export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "sync_params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sync params to container engine"])},
        "sync_params_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sync params to container engine."])},
        "sync_params_desc_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Will result in outage of service for several minutes."])},
        "desc_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task description"])}
      },
      "zh": {
        "sync_params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新容器引擎参数"])},
        "sync_params_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将更新所有节点的容器引擎参数，并重启容器引擎。"])},
        "sync_params_desc_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将会导致服务短暂不可用，请认真考虑后再执行操作。"])},
        "desc_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任务描述"])}
      }
    }
  })
}
