export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "install_cluster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install / Setup K8S Cluster"])},
        "requiresAtLeastOneOnlineNode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All nodes are offline."])},
        "requiresAllControlNodeOnline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("node")), " is offline. Remove it, or bring it back."])},
        "requireAtLeastOneControlPlane": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requires at least one control_plane."])},
        "requiresOddEtcdCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etcd count should be an odd number."])},
        "requiresKubeNodeCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requires at lease one kube_node."])},
        "requiresAllEtcdNodeOnline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["All Etcd nodes must be online, currently, we cannot reach node ", _interpolate(_named("node"))])},
        "nodesToIncludeDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Include nodes"])},
        "re_install_cluster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is going to repeat the installation steps on the following nodes, generally speaking, you don't need to do _this."])},
        "skip_downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip Downloads"])}
      },
      "zh": {
        "install_cluster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安装 / 设置集群"])},
        "requiresAtLeastOneOnlineNode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["至少需要一个在线的节点"])},
        "requiresAllControlNodeOnline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["安装集群或者添加控制节点时，所有控制节点必须在线。请启动 ", _interpolate(_named("node")), " 或者将其移除。"])},
        "requireAtLeastOneControlPlane": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["至少需要一个控制节点"])},
        "requiresOddEtcdCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETCD 节点的数量必须为奇数"])},
        "requiresKubeNodeCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["至少要有一个在线的工作节点"])},
        "requiresAllEtcdNodeOnline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["所有 ETCD 节点必须在线，当前 ", _interpolate(_named("node")), " 不在线"])},
        "nodesToIncludeDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包含节点"])},
        "re_install_cluster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此操作将在如下节点上重复执行一次集群安装的动作，通常您不需要这么做。"])},
        "skip_downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["跳过下载"])}
      }
    }
  })
}
