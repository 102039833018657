export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["net_checker"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["net_checker"])},
        "addon_function": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deploy some agents in K8S to verify if overlay network functions well."])}
      },
      "zh": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网络检查"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["net_checker"])},
        "addon_function": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在 K8S 中部署几个测试的 Agent，用于验证容器之间的网络是否互通。"])}
      }
    }
  })
}
