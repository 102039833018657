export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "proxy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proxy"])},
        "proxyDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proxy (Generally speaking, you don't need this.)"])},
        "proxyUsage1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KuboardSpray can use the following proxy params to fetch content from internet that are not included in the resource package."])},
        "proxyUsage2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usually speaking, all the resources (exclude docker-ce / OS mirror) are already included in the resource package, you don't need the proxy params here."])}
      },
      "zh": {
        "proxy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理"])},
        "proxyDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理（通常不需要设置）"])},
        "proxyUsage1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KuboardSpray 可以使用如下代理参数从外网获取资源包中未找到的资源；"])},
        "proxyUsage2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通常资源包中包含所需资源，您无需设置此处的代理参数；"])}
      }
    }
  })
}
