export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "cert_renew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renew Certificate"])},
        "cert_renew_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renew Certificates of APIServers restart APIServers one bye one."])},
        "desc_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task description"])}
      },
      "zh": {
        "cert_renew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新 APIServer 证书"])},
        "cert_renew_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将会逐个更新 APIServer 的证书并重启 APIServer"])},
        "desc_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任务描述"])}
      }
    }
  })
}
