export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "connectivity_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network connectivity check"])},
        "connectivity_check_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check if nodeport, hostport, containerport and service works in kubernetes cluster."])},
        "selectAPod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a pod first."])},
        "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passed network connectivity check"])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed network connectivity check"])},
        "netcheckerNotInstalled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You didn't install netchecker."])},
        "installNetchecker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install netchecker"])},
        "podList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pod list"])},
        "podDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pod details"])},
        "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
        "logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs"])}
      },
      "zh": {
        "connectivity_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网络连通性检查"])},
        "connectivity_check_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["检查访问节点端口、访问 hostPort、从容器访问其他容器、从容器访问 Service 等 K8S 各组件间的网络连通性。"])},
        "selectAPod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请先选择一个容器组。"])},
        "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网络连通性检查通过"])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网络连通性检查不通过"])},
        "netcheckerNotInstalled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未安装 “网络检查” 组件，请到集群规划页"])},
        "installNetchecker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安装 “网络检查” 组件"])},
        "podList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["容器组列表"])},
        "podDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["容器组详情"])},
        "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["事 件"])},
        "logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日 志"])}
      }
    }
  })
}
