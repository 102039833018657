export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "versionInResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version in installed Resource Package"])},
        "componentName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Component Name"])},
        "addon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addons"])},
        "network_plugin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cni plugins"])},
        "dependency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dependencies"])},
        "etcd_cluster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["etcd"])},
        "target_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version in target Resource Package"])}
      },
      "zh": {
        "versionInResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已安装资源包中的版本"])},
        "componentName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组件名称"])},
        "addon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可选组件"])},
        "network_plugin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网络插件"])},
        "dependency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["依赖组件"])},
        "etcd_cluster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["etcd"])},
        "target_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目标资源包中的版本"])}
      }
    }
  })
}
