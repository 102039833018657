export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eviction_hard"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eviction_hard params"])},
        "nolimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Limit"])}
      },
      "zh": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eviction_hard"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eviction_hard 参数"])},
        "kube_api_anonymous_auth_and_insecure_port": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不能同时禁用匿名用户和非安全端口"])},
        "nolimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不限制"])}
      }
    }
  })
}
