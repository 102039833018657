export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "cert_expiration_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cert expiration check"])},
        "cert_expiration_check_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check certificate expiration date of APIServer"])}
      },
      "zh": {
        "cert_expiration_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["证书有效期检查"])},
        "cert_expiration_check_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["检查 APIServer 的证书有效期"])}
      }
    }
  })
}
