export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operations"])},
        "download_binaries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribute installation binaries"])},
        "download_binaries_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the process of upgrading, we are going to change image version in some daemonsets, it would be the best if we distribute all required binaries and container images before we do the cactual upgrading."])},
        "skip_downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip distributing installation binaries"])},
        "upgrade_cluster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade cluster"])},
        "upgrade_multi_nodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade kube_nodes"])},
        "upgrade_multi_nodes_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select nodes to upgrade in this task run."])},
        "upgrade_single_node": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Upgrade ", _interpolate(_named("nodeName"))])},
        "upgrade_single_node_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade one node per task run."])},
        "upgrade_all_nodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade all nodes in one run"])},
        "upgrade_master_nodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade kube_control_plane and etcd nodes first"])},
        "uncordon_node": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uncordon node ", _interpolate(_named("nodeName"))])},
        "uncordon_node_desc": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uncordon node ", _interpolate(_named("nodeName")), " to bring it back."])},
        "drain_node": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Drain node ", _interpolate(_named("nodeName"))])},
        "drain_node_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drain node before upgrading it to avoid business interruption."])},
        "drain_timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["drain_timeout"])},
        "drain_retries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["drain_retries"])},
        "drain_grace_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["drain_grace_period"])},
        "drain_retry_delay_seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["drain_retry_delay_seconds"])},
        "nodes_to_remove_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select nodes to remove."])},
        "newResourcePackageRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This resource package does not support the operation, please choose a new one."])}
      },
      "zh": {
        "operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作选项"])},
        "download_binaries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分发安装包"])},
        "download_binaries_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["升级过程中，将会改变一些 daemonset 的版本（例如：kube-proxy, calico-node 等），如果不事先分发这些镜像到所有各个节点，将导致升级过程中，部分节点可能因为缺少对应版本的镜像而出现问题。"])},
        "skip_downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["跳过加载安装包"])},
        "upgrade_cluster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["升级集群"])},
        "upgrade_multi_nodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["升级多个工作节点"])},
        "upgrade_multi_nodes_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择本次任务中要升级的节点"])},
        "upgrade_single_node": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["升级 ", _interpolate(_named("nodeName"))])},
        "upgrade_single_node_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一次只升级一个节点。以下是当前节点上的容器组列表，如果存在非 DaemonSet 类型的容器组，请考虑是否先排空该节点。"])},
        "upgrade_all_nodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["升级所有节点"])},
        "upgrade_all_nodes_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一次性升级所有节点，如果当前有工作负载正在运行，将造成服务中断"])},
        "upgrade_master_nodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["升级控制节点和 ETCD 节点"])},
        "upgrade_master_nodes_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["先逐个升级控制节点和 ETCD 节点，然后再逐个排空、升级工作节点，避免造成服务中断。（如果有3个控制节点，大约耗时 18 分钟）"])},
        "uncordon_node": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["恢复调度 ", _interpolate(_named("nodeName"))])},
        "uncordon_node_desc": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["恢复调度 ", _interpolate(_named("nodeName")), "，以便新的容器组可以调度到该节点上。"])},
        "drain_node": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["排空节点 ", _interpolate(_named("nodeName"))])},
        "drain_node_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建议在升级节点前，排空节点上的容器组，以避免出现业务中断"])},
        "drain_timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排空节点超时时间"])},
        "drain_retries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排空节点重试次数"])},
        "drain_grace_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["应用停止等候时间"])},
        "drain_retry_delay_seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["两次排空尝试间隔"])},
        "nodes_to_remove_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择要删除的节点"])},
        "newResourcePackageRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前资源包不支持此操作，请使用新的资源包"])}
      }
    }
  })
}
