export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backup ETCD"])},
        "operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation"])},
        "backup_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backup ETCD data to kuboard-spray"])}
      },
      "zh": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["备份 ETCD"])},
        "operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
        "backup_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["备份 ETCD 数据到 kuboard-spray 所在机器"])}
      }
    }
  })
}
