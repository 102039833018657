export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": null,
      "zh": {
        "ipip_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ipip_mode"])},
        "vxlan_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vxlan_mode"])},
        "backend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["backend"])},
        "calico_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["calico_mode"])}
      }
    }
  })
}
