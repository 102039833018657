export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DNS"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network parameters to kubernetes."])},
        "nodelocaldnsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Node local DNS"])},
        "nodelocaldnsHelpStr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Node local DNS virtual IP, default value configed into pods' /etc/resolv.conf"])},
        "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["second"])},
        "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
        "applyDns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply DNS params to cluster."])},
        "verbose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Include task params"])},
        "verbose_": null,
        "verbose_v": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May include sensitive data in the trace, e.g. path to files, user name, password."])},
        "verbose_vvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["includes more information in log, only used in development."])},
        "v_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal"])},
        "v_v": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
        "v_vvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
        "fork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ansible fork"])},
        "fork_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max number of nodes can be operated in the installation."])}
      },
      "zh": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DNS"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kubernetes 集群的 DNS 参数"])},
        "nodelocaldnsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["节点 DNS"])},
        "nodelocaldnsHelpStr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["节点 DNS 的虚拟 IP 地址，将默认配置到各容器组的 /etc/resolv.conf 文件"])},
        "upstream_dns_servers_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上游 DNS 服务地址"])},
        "zone_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例如： mycompany.com"])},
        "cache_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["缓存时长，单位：秒"])},
        "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["秒"])},
        "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["应 用"])},
        "applyDns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将 DNS 设置参数应用到集群"])},
        "verbose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示任务参数"])},
        "verbose_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正常输出的日志，通常选用此选项"])},
        "verbose_v": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日志中会包含部分敏感信息，例如：文件路径、用户名密码等"])},
        "verbose_vvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日志中会包含最详细的信息，通常只在开发阶段使用"])},
        "v_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正常"])},
        "v_v": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["详细"])},
        "v_vvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更多"])},
        "fork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["并发数量"])},
        "fork_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安装过程中可以同时操作的目标节点的最大数量。ansible fork."])}
      }
    }
  })
}
