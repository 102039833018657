export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "add_insecure_registry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add insecure registry"])},
        "edit_insecure_registry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit insecure registry"])},
        "insecure_registries_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. http://192.168.30.56:5000"])},
        "domain_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain name of the registry"])},
        "domain_name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain name is required"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address of the registry"])}
      },
      "zh": {
        "add_insecure_registry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加 http 协议镜像仓库（或自签名 https 的镜像仓库）"])},
        "edit_insecure_registry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑 http 协议镜像仓库（或自签名 https 的镜像仓库）"])},
        "insecure_registries_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例如：http://192.168.30.56:5000"])},
        "domain_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["镜像仓库的域名"])},
        "domain_name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["镜像仓库的域名为必填字段"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["镜像仓库的地址"])}
      }
    }
  })
}
