export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "terminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open ssh terminal"])},
        "sshcommon": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["SSH Params (apply to node ", _interpolate(_named("nodeName")), ")"])},
        "etcd": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ETCD params (scope: node ", _interpolate(_named("nodeName")), ")"])},
        "etcd_member_name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please input etcd_member_name"])},
        "etcd_member_name_conflict": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["etcd_member_name conflict with that in node ", _interpolate(_named("nodeName"))])},
        "invalidName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hostname must consist of lower case alphanumeric characters or digit, and must start with an alphanumeric character"])},
        "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Node Role"])},
        "roleDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Node Role (scope: node ", _interpolate(_named("nodeName")), ")"])},
        "requiresAtLeastOneRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requires at least one role"])},
        "pendingDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Delete."])},
        "pendingDeleteAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click Execute button to do the actural deletion."])},
        "pendingAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Add."])},
        "pendingAddAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clieck Execute button to do the actural addition."])}
      },
      "zh": {
        "terminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["打开 SSH 终端"])},
        "sshcommon": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["SSH 连接参数（适用范围：节点 ", _interpolate(_named("nodeName")), "）"])},
        "etcd": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ETCD 参数（适用范围：节点 ", _interpolate(_named("nodeName")), "）"])},
        "etcd_member_name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请填写 ETCD 成员名称"])},
        "etcd_member_name_conflict": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ETCD成员名称与节点 ", _interpolate(_named("nodeName")), " 的ETCD成员名称冲突"])},
        "invalidName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必须由小写字母、数字组成，且必须以字母开头，以字母/数字结尾"])},
        "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["节点角色"])},
        "roleDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["节点角色（适用范围：节点 ", _interpolate(_named("nodeName")), "）"])},
        "requiresAtLeastOneRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["至少需要一个角色"])},
        "pendingDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等待删除"])},
        "pendingDeleteAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点击控制栏的 “节点待删除” 按钮，执行删除操作"])},
        "pendingAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等待添加"])},
        "pendingAddAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点击控制栏的 “安装/设置集群” 或 “添加节点” 按钮，执行添加操作"])}
      }
    }
  })
}
