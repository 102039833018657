export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "singleNode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific Node"])},
        "global_config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global Config"])},
        "addons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addons"])},
        "enabledBation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled"])},
        "disabledBation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabled"])},
        "selectANode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a node from the diagram to the left."])},
        "resourcePackage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource Package"])}
      },
      "zh": {
        "singleNode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单个节点"])},
        "global_config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全局设置"])},
        "addons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可选组件"])},
        "enabledBation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用跳板机"])},
        "disabledBation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不使用跳板机"])},
        "selectANode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请从左侧图中选择一个节点"])},
        "resourcePackage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资源包"])}
      }
    }
  })
}
