export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most frequently referenced params to Kubernetes."])},
        "nolimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Limit"])}
      },
      "zh": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通用参数"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kubernetes 集群的通用参数"])},
        "kube_api_anonymous_auth_and_insecure_port": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不能同时禁用匿名用户和非安全端口"])},
        "nolimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不限制"])}
      }
    }
  })
}
