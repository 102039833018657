export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "resourceVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource Package Version"])},
        "not_support_cluster_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current resource package doesnot support the operation to view cluster version, if you want to upgrade the cluster, please select a new resource package with the above button"])},
        "chooseNewResourcePackage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade to New ResourcePackage"])},
        "finished_upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finished upgrading cluster, previous version is"])},
        "finished_upgrade_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finished upgrading cluster"])}
      },
      "zh": {
        "resourceVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资源包版本"])},
        "not_support_cluster_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前资源包不支持查询集群版本的操作，如果您想要升级集群，请点击上面的按钮并选择新的资源包"])},
        "chooseNewResourcePackage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择新的资源包（升级集群）"])},
        "finished_upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已完成集群升级，升级前的资源包版本是"])},
        "finished_upgrade_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已完成集群升级"])}
      }
    }
  })
}
