export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "removeOneEtcdNodeOnce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to remove etcd node, can only remove them one by one."])},
        "resetNodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset nodes(non-applicable to offline nodes)"])},
        "resetNodesNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not reset nodes"])},
        "allow_ungraceful_removal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow remove nodes failed to drain"])},
        "allow_ungraceful_removal_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kuboard-spray does \"kubectl drain\" before removing nodes, continue on failure?"])},
        "drain_timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["drain_timeout"])},
        "drain_retries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["drain_retries"])},
        "drain_grace_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["drain_grace_period"])},
        "drain_retry_delay_seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["drain_retry_delay_seconds"])},
        "nodes_to_remove_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select nodes to remove."])}
      },
      "zh": {
        "removeOneEtcdNodeOnce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果要删除 etcd 节点，一次只能选择一个节点删除。"])},
        "resetNodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置节点（不能选择已停机的节点）"])},
        "resetNodesNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不重置节点"])},
        "allow_ungraceful_removal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许删除未排空的节点"])},
        "allow_ungraceful_removal_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除节点时，kuboard-spray 会自动执行如下指令以排空节点，如果失败，是否继续删除节点？"])},
        "drain_timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排空节点超时时间"])},
        "drain_retries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排空节点重试次数"])},
        "drain_grace_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["应用停止等候时间"])},
        "drain_retry_delay_seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["两次排空尝试间隔"])},
        "nodes_to_remove_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择要删除的节点"])}
      }
    }
  })
}
