export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metrics"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["metrics_server"])},
        "addon_function": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regularly takes metris info from K8S components, a must for features like Horizontal Auto Scaler."])},
        "metrics_server_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])}
      },
      "zh": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metrics"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["metrics_server"])},
        "addon_function": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["定时采集 K8S 各组件的性能数据，是容器组水平伸缩等功能的基础依赖。"])},
        "metrics_server_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["版本"])}
      }
    }
  })
}
