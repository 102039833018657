export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "verbose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Include task params"])},
        "verbose_": null,
        "verbose_v": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May include sensitive data in the trace, e.g. path to files, user name, password."])},
        "verbose_vvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["includes more information in log, only used in development."])},
        "v_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal"])},
        "v_v": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
        "v_vvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
        "fork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ansible fork"])},
        "fork_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max number of nodes can be operated in the installation."])},
        "control_params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control params"])},
        "operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operations"])},
        "install_cluster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install / Setup K8S Cluster"])},
        "add_node": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " nodes waiting to be added"])},
        "remove_node": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " nodes waiting to be removed"])},
        "aboutToRemoveNode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove nodes"])},
        "aboutToAddNode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add nodes"])},
        "offlineNodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline nodes"])},
        "offlineNodesDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclude the following offline nodes."])},
        "excludeNodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclude nodes"])},
        "excludeNodesDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclude the following nodes."])},
        "sync_etcd_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update apiserver's --etcd-servers param"])},
        "sync_nginx_config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update apiserver list in loadbalancer"])},
        "newResourcePackageRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This resource package does not support the operation, please choose a new one."])}
      },
      "zh": {
        "verbose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示任务参数"])},
        "verbose_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正常输出的日志，通常选用此选项"])},
        "verbose_v": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日志中会包含部分敏感信息，例如：文件路径、用户名密码等"])},
        "verbose_vvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日志中会包含最详细的信息，通常只在开发阶段使用"])},
        "v_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正常"])},
        "v_v": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["详细"])},
        "v_vvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更多"])},
        "fork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["并发数量"])},
        "fork_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安装过程中可以同时操作的目标节点的最大数量。ansible fork."])},
        "control_params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["控制选项"])},
        "operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作选项"])},
        "install_cluster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安装 / 设置集群"])},
        "add_node": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 个节点待添加"])},
        "remove_node": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 个节点待删除"])},
        "aboutToRemoveNode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除节点"])},
        "aboutToAddNode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加节点"])},
        "offlineNodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["离线节点"])},
        "offlineNodesDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排除以下不在线的节点："])},
        "excludeNodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排除节点"])},
        "excludeNodesDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排除以下节点"])},
        "sync_etcd_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新 apiserver 中 etcd 连接参数"])},
        "sync_nginx_config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新负载均衡中 apiserver 列表"])},
        "newResourcePackageRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前资源包不支持此操作，请使用新的资源包"])}
      }
    }
  })
}
