export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "addNode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Node"])},
        "nodeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Node Name"])},
        "nodeRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Node Roles"])},
        "conflict": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Conflict with a existing node ", _interpolate(_named("name")), "."])},
        "removeNodeFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please remove node or cancel removing node first."])},
        "invalidName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hostname must consist of lower case alphanumeric characters or '-', and must start with an alphanumeric character"])},
        "cannotUseKeyword": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cannot use ", _interpolate(_named("keyword")), " as node name."])},
        "nodeRequirement1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The machine on which KuboardSpray runs cannot be used as a k8s node."])},
        "nodeRequirement2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make sure to use a clean machine as a node (haven't tried to install k8s on it, and no other programs run on it)."])},
        "nodeRequirement3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaese make sure that all nodes are connected to each other via internal network."])}
      },
      "zh": {
        "addNode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加节点"])},
        "nodeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["节点名称"])},
        "nodeRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["节点角色"])},
        "conflict": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["与已有节点重名 ", _interpolate(_named("name"))])},
        "removeNodeFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请先删除或者取消删除节点"])},
        "invalidName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必须由小写字母、数字、减号组成，且必须以字母开头，以字母/数字结尾"])},
        "cannotUseKeyword": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["不能使用关键字 ", _interpolate(_named("keyword")), " 作为节点名称"])},
        "nodeRequirement1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KuboardSpray 所在机器不能用作 k8s 节点"])},
        "nodeRequirement2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请确保您的节点服务器是一个干净的机器（没有安装过 k8s 或 docker，不用来运行其他程序）"])},
        "nodeRequirement3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请确保所有节点之间可以通过内网 IP 地址互通"])}
      }
    }
  })
}
