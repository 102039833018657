export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update apiserver list in loadbalancer"])},
        "sync_nginx_config_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control_plane list in k8s cluster changed, this task is going to update 'upstream kube_apiserver' block in /etc/nginx/nginx.conf on all the kube_node nodes."])},
        "exclude_pending_add_nodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclude the following pending to add nodes."])}
      },
      "zh": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新负载均衡中 apiserver 列表"])},
        "sync_nginx_config_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集群中控制节点的列表发生变化，此操作将更新所有工作节点上 /etc/nginx/nginx.conf 文件中 upstream kube_apiserver 的列表"])},
        "exclude_pending_add_nodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排除以下待添加节点"])}
      }
    }
  })
}
