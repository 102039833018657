export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore ETCD"])},
        "operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation"])},
        "restore_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore backup data to ETCD cluster. You should not continue unless you know what you are doing."])}
      },
      "zh": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["恢复 ETCD 节点"])},
        "operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
        "restore_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将备份数据恢复到 ETCD 集群。请谨慎操作！此操作将会把 ETCD 集群所有节点的数据恢复到选定的快照。"])}
      }
    }
  })
}
