export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "ETCD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETCD Params (Apply to all the etcd nodes)"])}
      },
      "zh": {
        "ETCD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETCD 参数（适用范围：所有 etcd 节点）"])}
      }
    }
  })
}
