export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "versionInResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version in Resource Package"])},
        "componentName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Component Name"])},
        "addon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addons"])},
        "network_plugin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cni plugins"])},
        "dependency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dependencies"])},
        "etcd_cluster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["etcd"])},
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading versions installed on nodes"])},
        "command_for_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Command"])},
        "cordoned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cordoned"])}
      },
      "zh": {
        "versionInResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资源包中的版本"])},
        "componentName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组件名称"])},
        "addon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可选组件"])},
        "network_plugin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网络插件"])},
        "dependency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["依赖组件"])},
        "etcd_cluster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["etcd"])},
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查询节点上已安装的版本"])},
        "command_for_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查询命令"])},
        "cordoned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已暂停调度"])}
      }
    }
  })
}
