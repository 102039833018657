export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "createResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Resource Package"])},
        "goToResourcePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It's about to open Resource Package Download page in new window, do you confirm?"])}
      },
      "zh": {
        "createResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加资源包"])},
        "goToResourcePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此操作将在新窗口打开资源包导入页面，完成设置后，您可以切换窗口会到当前页面，是否继续？"])}
      }
    }
  })
}
