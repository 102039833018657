export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "gapNode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Node in cluster, not in inventory"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fulfill its info here, and add to kuboardspray."])},
        "addToKuboardSpray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to KubaordSpray"])}
      },
      "zh": {
        "gapNode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集群中存在，而 KuboardSpray 中未记录的节点"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["该节点在集群中存在，但是却不存在于 KuboardSpray 记录的信息中，可能是因为您通过其他途径向集群添加了该节点。"])},
        "addToKuboardSpray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加到 KuboardSpray"])}
      }
    }
  })
}
