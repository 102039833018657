export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audit Log"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kubernetes audit log"])}
      },
      "zh": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["审计日志"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kubernetes 审计日志"])}
      }
    }
  })
}
