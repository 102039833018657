export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "verbose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Include task params"])},
        "verbose_": null,
        "verbose_v": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May include sensitive data in the trace, e.g. path to files, user name, password."])},
        "verbose_vvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["includes more information in log, only used in development."])},
        "v_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal"])},
        "v_v": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
        "v_vvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
        "fork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ansible fork"])},
        "fork_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max number of nodes can be operated in the installation."])},
        "control_params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control params"])},
        "operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operations"])},
        "offlineNodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline nodes"])},
        "offlineNodesDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclude the following offline nodes."])}
      },
      "zh": {
        "verbose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示任务参数"])},
        "verbose_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正常输出的日志，通常选用此选项"])},
        "verbose_v": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日志中会包含部分敏感信息，例如：文件路径、用户名密码等"])},
        "verbose_vvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日志中会包含最详细的信息，通常只在开发阶段使用"])},
        "v_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正常"])},
        "v_v": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["详细"])},
        "v_vvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更多"])},
        "fork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["并发数量"])},
        "fork_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安装过程中可以同时操作的目标节点的最大数量。"])},
        "control_params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["控制选项"])},
        "operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作选项"])},
        "offlineNodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["离线节点"])},
        "offlineNodesDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排除以下不在线的节点："])}
      }
    }
  })
}
