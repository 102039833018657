export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto rotate certificates"])},
        "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check certificates expiration"])}
      },
      "zh": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["证书更新"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动更新证书"])},
        "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["检查证书有效期"])}
      }
    }
  })
}
