export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update apiserver's --etcd-servers param"])},
        "requiresAllControlNodeOnline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("node")), " is offline. Remove it, or bring it back."])},
        "finishAddRemoveActionFirst": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please finish/cancel ", _interpolate(_named("action")), " action on node ", _interpolate(_named("node")), " first."])},
        "requireAtLeastOneControlPlane": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requires at least one control_plane."])},
        "sync_etcd_address_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member list of etcd cluster changed, this task is going to update --etcd-servers param in /etc/kubernetes/manifests/kube-apiserver.yaml on all the remaining kube_control_plane nodes, to match the latest etcd member list."])}
      },
      "zh": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新 apiserver 中 etcd 连接参数"])},
        "requiresAllControlNodeOnline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["安装集群或者添加控制节点时，所有控制节点必须在线。请启动 ", _interpolate(_named("node")), " 或者将其移除。"])},
        "finishAddRemoveActionFirst": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["请先完成或取消节点 ", _interpolate(_named("node")), " 的 ", _interpolate(_named("action")), " 操作"])},
        "requireAtLeastOneControlPlane": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["至少需要一个控制节点"])},
        "sync_etcd_address_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETCD 集群的成员列表已经发生变化，此操作将更新剩余控制节点上 /etc/kubernetes/manifests/kube-apiserver.yaml 文件中 --etcd-servers 的参数，以匹配最新的 etcd 成员列表。"])}
      }
    }
  })
}
