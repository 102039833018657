export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose resource package to upgrade to."])},
        "currentVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently selected"])},
        "cannot_down_grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K8S version in the package is lower"])},
        "switchToTargetVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replace with this version"])},
        "switchToTargetVersionDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Component version in this resource package are the same with the currently installed one, but ansible script are different. You don't need to perform a cluster upgrade by selecting this resource package."])},
        "upgradeToTargetVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade to this version"])},
        "upgradeToTargetVersionDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After select this resource package you need to perform a cluster upgrade to keep with the latest version."])},
        "cannotUpgradeToTargetVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot upgrade to this version"])},
        "downloadFirstCanUpgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can upgrade, go download"])},
        "downloadFirstCanReplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can replace, go download"])},
        "change_resource_package_version_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succeed in changing resource package version"])},
        "change_resource_package_version_failed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Failed to change resource package version ", _interpolate(_named("msg"))])},
        "not_supported_cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Container manager not match"])}
      },
      "zh": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择升级时使用的资源包"])},
        "currentVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前版本"])},
        "cannot_down_grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资源包中K8S版本过低"])},
        "switchToTargetVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用此版本替代"])},
        "switchToTargetVersionDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此资源包中的组件版本与当前已安装版本相同，但是 ansible 脚本有所不同，替换后，不需要对集群执行升级操作。"])},
        "upgradeToTargetVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["升级到此版本"])},
        "upgradeToTargetVersionDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择此版本后，您需要执行集群升级动作，以更新集群的版本。"])},
        "cannotUpgradeToTargetVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不能升级到此版本"])},
        "downloadFirstCanUpgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可升级，去下载"])},
        "downloadFirstCanReplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可替代，去下载"])},
        "change_resource_package_version_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已成功修改资源包版本"])},
        "change_resource_package_version_failed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["修改资源包版本失败 ", _interpolate(_named("msg"))])},
        "not_supported_cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不支持当前容器引擎"])}
      }
    }
  })
}
