export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "confirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure to delete this node ?"])},
        "noRemoveOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cannot remove node when cluster is unreachable"])},
        "addNodeFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please complete or cancel the deletion of nodes first."])},
        "cannot_remove_last_etcd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot remove the last etcd node"])},
        "cannot_remove_last_master": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot remove the last kube_control_plane"])}
      },
      "zh": {
        "confirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您是否确认要删除此节点？"])},
        "noRemoveOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集群不在线，不能删除节点"])},
        "addNodeFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请先完成或取消节点添加动作，再执行节点删除动作。"])},
        "cannot_remove_last_etcd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不能删除最后一个 ETCD 节点"])},
        "cannot_remove_last_master": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不能删除最后一个控制节点"])}
      }
    }
  })
}
