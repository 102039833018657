export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OS Mirror"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation System source repo."])},
        "selectOs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select OS"])},
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])},
        "addSource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Source"])},
        "asis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use pre-configured source in the OS."])},
        "docker_asis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use docker official repository download.docker.com"])},
        "goToMirrorPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It's about to open OS Software Source management page in new window, do you confirm?"])},
        "install_docker_from_distro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SUSE distributions always install Docker from the distro repos"])}
      },
      "zh": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["软件源"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OS 软件源（为操作系统指定软件源，例如 yum 源、apt 源等）"])},
        "selectOs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择操作系统"])},
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["源"])},
        "addSource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加软件源"])},
        "asis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用操作系统预先配置的软件源"])},
        "docker_asis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用 Docker 官方软件源 download.docker.com"])},
        "goToMirrorPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此操作将将在新窗口打开软件源管理页面，完成设置后，可以切换窗口回到本页面，是否继续？"])},
        "install_docker_from_distro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SUSE 将始终从操作系统的软件源中安装 Docker"])}
      }
    }
  })
}
