export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nodelocaldns"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["node local dns cache"])},
        "addon_function": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cache DNS result on node."])}
      },
      "zh": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["节点 DNS"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["节点 DNS 缓存"])},
        "addon_function": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在所有节点上缓存 DNS 的解析结果"])}
      }
    }
  })
}
