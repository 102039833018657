export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "node": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Node name"])},
        "etcd_member_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETCD member name"])},
        "backup_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backup name"])},
        "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
        "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])},
        "delete_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete selected backups."])},
        "select_one_backup_to_restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select one backup file to restore etcd cluster."])}
      },
      "zh": {
        "node": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["节点名称"])},
        "etcd_member_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETCD 成员名称"])},
        "backup_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["备份名称"])},
        "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["备份文件大小"])},
        "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刷 新"])},
        "delete_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除选中的备份文件。"])},
        "select_one_backup_to_restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择一个备份文件即可恢复整个 ETCD 集群"])}
      }
    }
  })
}
