export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "execute_scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-Execute CIS Scan"])},
        "expected_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected Result"])},
        "remediation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remediation"])},
        "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["manual"])},
        "actual_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actual value"])},
        "audit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audit command"])},
        "AuditEnv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audit Env"])},
        "AuditConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AuditConfig"])},
        "expected_and_remediation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected and remediation"])},
        "last_run_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scaned at"])}
      },
      "zh": {
        "execute_scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新执行 CIS 扫描"])},
        "expected_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预期结果"])},
        "remediation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["问题修复"])},
        "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人工"])},
        "actual_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["实测结果"])},
        "audit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["审计命令"])},
        "AuditEnv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["审计命令环境变量"])},
        "AuditConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["审计命令配置文件"])},
        "expected_and_remediation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预期及修复"])},
        "last_run_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["检查时间"])}
      }
    }
  })
}
