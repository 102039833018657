export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "addSshKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Private Key"])},
        "ansible_host_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KuboardSpray use this ip or hostname to connect to the node."])},
        "default_value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Default: ", _interpolate(_named("default_value")), " (inhirit from value configured in Global Config tab)"])},
        "duplicateIP": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["IP address conflict with ", _interpolate(_named("node"))])},
        "ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bind to IP"])},
        "ip_placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Default: ", _interpolate(_named("default_value")), " (inhirit from value ansible_host)"])},
        "longTimeLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading... cost about 20-30s"])},
        "password_and_bastion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are using bastion/jumpserver to access the node, if you use password here, it takes longer time to create ssh connection, it would be better to clear the password and provide ssh_private_key."])},
        "password_in_global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You defined password in global configuration, it's suggested that you clear it."])},
        "speedup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSH Multiplexing"])},
        "cannotUseLocalhostAsTarget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot use the machine that kuboardspray runs on as a target node."])},
        "inhirit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inhirit from value configured in Global Config tab"])},
        "rootuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must use root user"])}
      },
      "zh": {
        "addSshKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加私钥"])},
        "ansible_host_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KuboardSpray 连接该主机时所使用的主机名或 IP 地址"])},
        "default_value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["默认值：", _interpolate(_named("default_value")), " （继承自全局设置标签页中的配置）"])},
        "duplicateIP": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["IP 地址不能与其他节点相同：", _interpolate(_named("node"))])},
        "ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["绑定到 IP"])},
        "ip_placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["默认值：", _interpolate(_named("default_value")), " （默认与主机 IP 相同）"])},
        "longTimeLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载中... 可能需要 20-30 秒"])},
        "ipDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kubernetes 使用的地址有可能与 kuboard-spray 访问该机器时所使用的地址不同，此处指定 kubernetes 所使用的地址（必须为内网地址）"])},
        "password_and_bastion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您将使用跳板机访问节点，如果在此处使用密码访问，创建 ssh 连接的时间较长，并且有很大的概率会发生ssh连接断开的情况，建议清除密码并提供 “私钥文件”。"])},
        "password_in_global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您在全局参数中配置了密码，建议清除。"])},
        "speedup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加速执行"])},
        "cannotUseLocalhostAsTarget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不能使用 KuboardSpray 所在机器作为目标节点"])},
        "inhirit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继承自全局设置标签页中的配置"])},
        "rootuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必须使用 root 用户"])}
      }
    }
  })
}
