export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "sshcommon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSH Shared Params (apply to all the k8s nodes)"])}
      },
      "zh": {
        "sshcommon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSH 共享参数（适用范围：所有 k8s 节点）"])}
      }
    }
  })
}
