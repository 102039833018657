export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuboard"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kuboard"])},
        "addon_function": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A cool kubernetes dashboard."])}
      },
      "zh": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuboard"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kuboard"])},
        "addon_function": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kubernetes 管理界面。"])}
      }
    }
  })
}
